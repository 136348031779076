//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
//根据userId获取人才认证信息
const getTalentsIntroduceInfoByUserId = `/gateway/hc-serve/mini/talents/getTalentsIntroduceInfoByUserId`;
//保存或更新人才认证审核表
const saveOrUpdateTalentsIntroduce = `/gateway/hc-serve/mini/talents/saveOrUpdateTalentsIntroduce`;

// 查询 - 人员/工会会员认证 - 信息
const getRyGhhyAuthInfoUrl = `/gateway/hc-serve/mini/talents/getTalentsIntroduceInfoByUserId`;

// 查询 - 用户实名认证 - 信息
const getUserRealNameAuthInfoUrl = `/gateway/blade-user/user/getAuthUserInfo`;

// 查询 - 人员/工会会员认证 - 详情
const getRyGhhyAuthDetailUrl = `/gateway/hc-serve/mini/talents/getTalentsIntroduceInfoById`;

// 保存 - 人员/工会会员认证 - 信息
const saveRyGhhyAuthInfoUrl = `/gateway/hc-serve/mini/talents/saveOrUpdateTalentsIntroduce`;

// 撤回 - 人员/工会会员认证 - 信息
const revoRyGhhyAuthInfoUrl = `/gateway/hc-serve/mini/talents/revoke`;

export {
  userInfoUrl,
  getTalentsIntroduceInfoByUserId,
  saveOrUpdateTalentsIntroduce,
  getRyGhhyAuthInfoUrl,
  getUserRealNameAuthInfoUrl,
  getRyGhhyAuthDetailUrl,
  saveRyGhhyAuthInfoUrl,
  revoRyGhhyAuthInfoUrl,
};
